<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>楼栋管理</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
      <el-row class="content-box">
         <!-- 主体内容 -->
         <el-row class="table-box">
            <div class="table-head-btn">
               <el-row><h6 class="font-18">楼栋列表</h6></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" icon="el-icon-circle-plus" @click="handleAddBuild" ><span v-text="$t('msg.building_add')">添加楼栋</span></el-button>
               </el-row>
            </div>
            <!-- 表格 -->
            <el-table
                  ref="buildingTable"
                  v-loading="buildLoad"
                  :data="buildData"
                  border fit
                  highlight-current-row
                  @current-change="changeCurrBuild"
                  :stripe="true">
               <el-table-column :label="$t('msg.number')">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="buildName" :label="$t('msg.building_name')"></el-table-column>
               <el-table-column prop="buildNo" :label="$t('msg.building_number')"></el-table-column>
               <el-table-column prop="hotelName" :label="$t('msg.own_hotel')" min-width="110"></el-table-column>
               <el-table-column prop="buildTime" :label="$t('msg.build_time')" min-width="150" sortable></el-table-column>
               <el-table-column prop="aspect" :label="$t('msg.build_towards')"></el-table-column>
               <el-table-column :label="$t('msg.operating')" width="200"  fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-edit" @click="handleEditBuild(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="delBuild(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <el-row class="p-t-m-40"></el-row>
            <!-- 表格按钮 -->
            <div class="table-head-btn">
               <el-row><h6 class="font-18">单元列表</h6></el-row>
               <el-row class="right-btn">
                   <el-button class="bg-gradient"  v-if="buildData.length > 0" type="primary" icon="el-icon-circle-plus" @click="handleAddUnit" ><span v-text="$t('msg.unit_add')">添加单元</span></el-button>
               </el-row>
            </div>
            <!-- 表格 -->
            <el-table
                  :data="unitData"
                  border fit
                  highlight-current-row
                  style="width: 100%"
                  :stripe="true" >
               <el-table-column :label="$t('msg.number')">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="unitName" :label="$t('msg.unit_name')"></el-table-column>
               <el-table-column prop="unitNo" :label="$t('msg.unit_number')"></el-table-column>
               <el-table-column prop="hotelName" :label="$t('msg.own_hotel')" min-width="110"></el-table-column>
               <el-table-column prop="buildName" :label="$t('msg.unit_build')"></el-table-column>
               <el-table-column prop="buildNum" :label="$t('msg.build_number')"></el-table-column>
               <el-table-column prop="createTime" :label="$t('msg.create_time')" min-width="150" sortable></el-table-column>
               <el-table-column :label="$t('msg.operating')" width="200"  fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-edit" @click="handleEditUnit(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="delUnit(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
         <!-- 新建/修改楼栋 -->
         <el-dialog :title="$t('msg.building_add')" :visible.sync="buildPopup" width="550px" :before-close="closeBuild">
            <el-form class="form-dialog-box">
               <el-form-item required label="楼栋名称">
                  <el-input  class="width-300" v-model="buildName" :placeholder="$t('msg.building_name')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="楼栋编号">
                  <el-input  class="width-300" v-model="buildNo" :placeholder="$t('msg.building_number')" clearable></el-input>
               </el-form-item>
               <el-form-item label="修建时间">
                  <el-date-picker
                     class="width-300"
                     v-model="buildTime"
                     type="date"
                     :placeholder="$t('msg.build_time')">
                  </el-date-picker>
               </el-form-item>
               <el-form-item label="朝向">
                  <el-select  class="width-300" v-model="aspect" :placeholder="$t('msg.select')" clearable>
                     <el-option v-for="item in aspectList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="resetBuild" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="saveBuild" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
         <!-- 新建/修改单元 -->
         <el-dialog :title="$t('msg.unit_add')" :visible.sync="unitPopup" width="510px" :before-close="closeUnit">
            <el-form class="form-dialog-box">
               <el-form-item required label="单元名称">
                  <el-input  class="width-300" v-model="unitName" :placeholder="$t('msg.unit_name')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="单元编号">
                  <el-input  class="width-300" v-model="unitNo" :placeholder="$t('msg.unit_number')" clearable></el-input>
               </el-form-item>
               <el-form-item style="width:100%" required label="楼层数">
                  <el-input  class="width-140" v-model="beginFloor" placeholder="开始层数" clearable></el-input>
                  —
                  <el-input  class="width-140" v-model="endFloor" placeholder="结束层数" clearable></el-input>
               </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
               <el-button  @click="resetUnit()" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSaveUnit()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
      </el-row>
   </section>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   import { mapState } from 'vuex'
   export default {
      data () {
         return {
            buildData: [],      // 楼栋数据
            unitData: [],       // 单元数据
            aspectList: [
               {
                  value: '东',
                  label: '东'
               },
               {
                  value: '西',
                  label: '西'
               },
               {
                  value: '南',
                  label: '南'
               },
               {
                  value: '北',
                  label: '北'
               },
               {
                  value: '坐北朝南',
                  label: '坐北朝南'
               },
               {
                  value: '坐西朝东',
                  label: '坐西朝东'
               }
            ],  // 朝向列表
            buildLoad: true,    // 楼栋列表加载效果
            buildPopup: false,  // 楼栋表单弹窗
            unitPopup: false,   // 单元表单弹窗
            buildAction: '',    // 楼栋的操作类型
            unitAction: '',     // 单元的操作类型
            /* 楼栋列表查询字段 */
            hotelId: '',        // 酒店ID
            /* 单元列表查询字段 */
            buildId: '',        // 楼栋ID
            /* 楼栋新增字段 */
            //hotelId: '',      // 酒店ID
            buildName: '',      // 楼栋名称
            buildNo: '',        // 楼栋编号
            buildTime: '',      // 修建时间
            aspect: '',         // 楼栋朝向
            /* 单元新增字段 */
            unitName: '',       // 单元名称
            unitNo: '',         // 单元编号
            beginFloor: '',     // 最底层
            endFloor: '',       // 最高层
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            hotel_no_null: '酒店名称不能为空！',
            hotel_code_no_null: '酒店编码不能为空！',
            hotel_type_no_null: '酒店类型不能为空！',
            start_floor_no_null: '开始楼层不能为空！',
            end_floor_no_null: '结束楼层不能为空！',
            start_floor_no_end: '开始楼层不能大于结束楼层！',
            sel_up_hotel: '请选择上级酒店！',
            hotel_phone_no_null: '酒店电话不能为空！',
            mailbox_error: '邮箱格式不正确！',
            select_applet: '请选择小程序授权菜单！',
            add_success: '添加成功！',
            update_success: '修改成功！',
            prompt: '提示！',
            confirm_edit: '确定修改？',
            confirm_remove: '确定移除？',
            confirm_remove_hotel: '若删除酒店，将删除其下面所有的部门、人员数据，确定删除？',
            remove_success: '移除成功！',
            bind_success: '绑定成功！',
            room_bind_no_null: '请选择要绑定的房型！',
            room_type_no_null: '房间类型(中文)不能为空！',
            room_type_en_no_null: '房间类型(英文)不能为空！',
            room_type_no_equals: '房间类型不能相同！',
            room_type_no_def_equals: '房间类型不能跟默认的相同！',
            build_name_no_null: '楼栋名称不能为空！',
            build_nubmer_no_null: '楼栋编号不能为空！',
            unit_name_no_null: '单元名称不能为空！',
            unit_nubmer_no_null: '单元编号不能为空！',
            on_layers_no_null: '地上层数不能为空！',
            under_layers_no_null: '地下层数不能为空！',
            under_on_no_null: '地下层数不能大于地上层数！',
         }
      },
      computed:{
         ...mapState(['hotelInfo'])
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.getBuildings()
      },
      methods: {
         // 获取楼栋列表
         getBuildings(){
            const url = urlObj.getBuildList
            let param = {
               hotelId: this.hotelId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.buildLoad = false
                  this.buildData = res.records
                  this.$refs.buildingTable.setCurrentRow(this.buildData[0]);
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 选中当前楼栋
         changeCurrBuild(val){
            if (val){
               this.buildId = val.id
               this.getUnits()
               return
            }
            this.unitData = []
         },
         // 获取单元列表
         getUnits(){
            const url = urlObj.getBuildUnitList
            const param = {
               buildId: this.buildId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let unitData = res.records
                  unitData.forEach(item => {
                     if (item.beginFloor <= 0) {
                        item.buildNum = item.endFloor
                     } else {
                        item.buildNum = (item.endFloor - item.beginFloor) + 1
                     }
                  })
                  this.unitData = unitData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 添加楼栋
         handleAddBuild(){
            this.buildPopup = true
            this.buildAction = 'add'
         },
         // 编辑楼栋
         handleEditBuild(row){
            this.buildPopup = true
            this.buildAction = 'edit'
            // 表单回显
            this.buildId = row.id
            this.buildName = row.buildName
            this.buildNo = row.buildNo
            this.buildTime = row.buildTime
            this.aspect = row.aspect
         },
         // 重置楼栋表单
         resetBuild(){
            this.buildName = ""
            this.buildNo = ""
            this.buildTime = ""
            this.aspect = ""
         },
         // 保存楼栋操作
         saveBuild(){
            if (!this.buildName) {
               this.$alert(this.build_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.buildNo) {
               this.$alert(this.build_nubmer_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            let url = urlObj.addbuild
            let param = {
               hotelId: this.hotelId,
               buildName: this.buildName,
               buildNo: this.buildNo,
               buildTime: this.formatDate(this.buildTime,'yyyy-MM-dd'),
               aspect: this.aspect
            }
            if (this.buildAction === 'edit'){
               param.id = this.buildId
               url = urlObj.editbuild
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.buildAction === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.closeBuild()
                  this.getBuildings()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // formatDate函数为扩展函数。
         formatDate (date, fmt) {
            if (!date || !(date instanceof Date)) {
               return date
            }
            if (/(y+)/.test(fmt)) {
               fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
            }
            let o = {
               'M+': date.getMonth() + 1,
               'd+': date.getDate(),
               'h+': date.getHours(),
               'm+': date.getMinutes(),
               's+': date.getSeconds()
            }
            for (let k in o) {
               if (new RegExp(`(${k})`).test(fmt)) {
                  let str = o[k] + ''
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
               }
            }
            return fmt
         },
         padLeftZero (str) {
            return ('00' + str).substr(str.length)
         },
         // 关闭楼栋弹窗
         closeBuild(){
            this.buildPopup = false
            this.resetBuild()
         },
         // 删除楼栋
         delBuild(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               const url = urlObj.delbuild + `/${id}`
               this.$axios.get(url, {}).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getBuildings(this.hotelId)
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
         // 添加单元
         handleAddUnit(){
            this.unitAction = 'add'
            this.unitPopup = true
         },
         // 编辑单元
         handleEditUnit(row){
            this.unitAction = 'edit'
            this.unitPopup = true
            // 回显单元
            this.unitId = row.id
            this.buildId = row.buildId
            this.unitName = row.unitName
            this.unitNo = row.unitNo
            this.beginFloor = row.beginFloor
            this.endFloor = row.endFloor
         },
         // 重置单元表单
         resetUnit(){
            this.unitName = ""
            this.unitNo = ""
            this.beginFloor = ""
            this.endFloor = ""
         },
         // 保存单元操作
         handleSaveUnit(){
            if (!this.unitName) {
               this.$alert(this.unit_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.unitNo) {
               this.$alert(this.unit_nubmer_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.beginFloor) {
               this.$alert(this.under_layers_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.endFloor) {
               this.$alert(this.on_layers_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            // 地上楼层不能大于结束楼层！(编辑)
            if (Number(this.beginFloor) > Number(this.endFloor)) {
               this.$alert(this.under_on_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addUnit
            let param = {
               hotelId: this.hotelId,
               buildId: this.buildId,
               unitName: this.unitName,
               unitNo: this.unitNo,
               beginFloor: this.beginFloor,
               endFloor: this.endFloor
            }
            if (this.unitAction === 'edit'){
               url = urlObj.editUnit
               param.id = this.unitId
               delete param.hotelId
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.unitAction ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.getUnits()
                  this.closeUnit()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭单元弹窗
         closeUnit(){
            this.unitPopup = false
            this.resetUnit()
         },
         // 删除单元
         delUnit(id){
            const url = urlObj.delUnit + `/${id}`
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               this.$axios.get(url, {}).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getUnits()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.getBuildings()
               }
            }
         }
      },
   }
</script>

<style scoped lang="scss"></style>
